//import { ApolloClient } from "apollo-boost";
import { ApolloClient, InMemoryCache } from "@apollo/client";
//import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { AUTH_TOKEN, TOKEN_TYPE } from "./config/constants";
import { isAuthenticated } from "./lib/auth";
import { IS_CUSTOMER_VAL } from "./config/typecheck";
import { getStorageValueToken } from "./utils/getMiniAppStorageData";

const clientV3 = (endpoint) => {
  const authLink = setContext((_, { headers }) => {
    const tokenType = !window.location.search.includes("miniapp")
      ? localStorage.getItem("token_type")
      : localStorage.getItem("accessType");
    const token = !window.location.search.includes("miniapp")
      ? localStorage.getItem("access_token")
      : localStorage.getItem("access");
    return {
      headers: {
        ...headers,
        authorization: token ? `${tokenType} ${token}` : "",
      },
    };
  });

  const httpLinkV3 = new createHttpLink({
    uri:
      process.env.REACT_APP_BE_SERVER +
      `${
        endpoint
          ? endpoint
          : (isAuthenticated() ||
              (window.location.search.includes("miniapp") &&
                localStorage.getItem("access") &&
                localStorage.getItem("access") !== "initial")) &&
            IS_CUSTOMER_VAL //for customer
          ? process.env.REACT_APP_GQL_CUSTOMER
          : !isAuthenticated() //for guest
          ? process.env.REACT_APP_GQL_GUEST
          : process.env.REACT_APP_GQL_DEFAULT // for b2b and others
      }`,
  });

  return new ApolloClient({
    link: authLink.concat(httpLinkV3),
    cache: new InMemoryCache(),
  });
};

export default clientV3;
